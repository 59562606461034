<template>
<!--  平台会议总结  -->
  <div class="ConferenceSettlement" style=" margin: -15px -15px;">
    <a-spin :spinning="spinning">
      <div class="top">
        <div class="liveMeet-top-input" style="width: 15%">
          <div>年份: </div>
          <a-date-picker
              v-model="year"
              mode="year"
              v-decorator="['year']"
              placeholder="请输入"
              format="YYYY"
              class="input"
              :open="yearShowOne"
              @openChange="openChangeOne"
              @panelChange="panelChangeOne"/>
        </div>
        <div class="liveMeet-top-input" style="width: 25%">
          <div>状态:</div>
          <a-radio-group
              style="margin-left: 5px"
              v-model="radioValue"
              @change="onChange">
            <a-radio-button value="0">
              待结算
            </a-radio-button>
            <a-radio-button value="1">
              已结算
            </a-radio-button>
          </a-radio-group>
        </div>
        <div class="liveMeet-top-input">
          <a-button
              @click="seachBtn"
              type="primary">查询</a-button>
          <a-button
              @click="resetBtn"
              style="margin-left: 10px">重置</a-button>
        </div>
      </div>
      <a-row >
        <a-col class="box-card" :span="6" v-for="list in summarizeList" :key="list.id">
          <div class="card">
            <div class="card-img">
              <div class="card-top">
                <a-switch
                    v-model="list.isAudit === 0?false:true"
                    style="margin-top: 3px;margin-right: 10px"
                    checked-children="已结算"
                    un-checked-children="待结算"
                    default-checked />
              </div>
              <img class="img" :src="list.coverUrl"/>
              <div class="card-bottom">
                <span>负责人：{{list.leaderSales}} {{list.leaderOperate}}</span>
              </div>
            </div>
            <div class="title">
              <div class="title-t">{{list.title}}</div>
              <div class="title-liveTime">直播时间：{{list.liveDate}}</div>
            </div>
            <div class="bottom">
              <div @click="lookBtn(list.id)">查看</div>
              <div style="color: #e3e3e3">|</div>
              <div  @click="dataBtn(list.id)">导出</div>
              <div style="color: #e3e3e3">|</div>
              <div @click="openVue(list)">名单</div>
            </div>
          </div>
        </a-col>
      </a-row>
      <div style="width: 100%;display: flex;justify-content: right">
        <div style="margin-right: 20px">
          <a-pagination show-quick-jumper
                        :default-current="pageNo"
                        :total="total"
                        @change="pageChange" />
        </div>
      </div>
    </a-spin>
<!-----------------------------------------------------------对话框-------------------------->
    <!--   查看   -->
    <a-drawer
        title="查看"
        :width="900"
        :visible="visible"
        :body-style="{ paddingBottom: '80px' }"
        @close="resetForm"
    >
      <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :layout="vertical"
      >
        <div class="modelSty">
          <div class="title-model">图片材料</div>
          <div style="display: flex">
            <div><a-form-model-item  label="详情封面图">
              <div style="display: flex;width: 450px;">
<!--                <div class="uploadImg" >-->
<!--                  <input-->
<!--                      id="detailCoverUrlId"-->
<!--                      style="width: 100%;height:100%;opacity: 0;"-->
<!--                      accept="image/*"-->
<!--                      type="file"-->
<!--                      @change="upDetailsCover"-->
<!--                      ref="detailCoverUrl" />-->
<!--                </div>-->
                <div
                    class="upload_deyails">
                  <img
                      v-if="form.coverUrl"
                      alt=""
                      style="width: 100%;height:100%"
                      :src="form.coverUrl"
                  />
                </div>
              </div>
              <!--          <div style="color: #a1a1a1">注:尺寸640*360，大小不超过500KB</div>-->
            </a-form-model-item></div>
            <div><a-form-model-item  label="详情海报">
              <div style="display: flex;width: 450px;">
<!--                <div class="uploadImg" >-->
<!--                  <input-->
<!--                      id="descImageUrlId"-->
<!--                      style="width: 100px;height: 100px;opacity: 0;"-->
<!--                      accept="image/*"-->
<!--                      type="file"-->
<!--                      @change="upPosterCover"-->
<!--                      ref="descImageUrl" />-->
<!--                </div>-->
                <div
                    class="upload_poster">
                  <img
                      v-if="form.descImageUrl"
                      alt=""
                      style="width: 100%;height:100%"
                      :src="form.descImageUrl"
                  />
                </div>
              </div>
              <!--          <div style="color: #a1a1a1">注:尺寸宽640，大小不超过3M</div>-->
            </a-form-model-item></div>
          </div>
          <div class="title-model">直播链接</div>
          <div style="display: flex">
            <a-input :disabled="true" id="copy" style="margin-bottom: 20px" v-model="form.targetUrl" placeholder="请输入"/>
            <div style="color: #1374ce;margin-left: 10px;">
              <a-icon @click="copy(form.targetUrl)" type="copy" />
            </div>
          </div>
          <div class="title-model">回放链接</div>
          <div>
            <a-table  :columns="communityColumns" :row-key="record => record.id" :data-source="form.liveStreamVideoList">
               <span slot="system" slot-scope="text, record">
                 <a @click="openVideo(record.videoUrl)">打开链接</a>
               </span>
              <span slot="type" slot-scope="text, record">
                 <span v-if="!record.taskId">剪辑上传</span>
                 <span v-else>自动生成</span>
               </span>
            </a-table>
          </div>
        </div>



      </a-form-model>
      <div
          :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="resetForm">
          关闭
        </a-button>
<!--        <a-button type="primary" @click="onSubmit">-->
<!--          确认-->
<!--        </a-button>-->
      </div>
    </a-drawer>
  </div>
</template>

<script>

import moment from "moment";
import {update} from "@/utils/update";
import {manageListDetail, myManageList} from "@/service/MedicalConference_y";
import {current_date} from "@/utils/currentDate";

export default {
  name:'ConferenceSettlement',
  data(){
    return{
      total:0,
      year:current_date(),
      yearShowOne:false,
      spinning:false,
      visible:false,
      radioValue:'0',
      activeKey: ['1'],
      summarizeList:[],
      form: {
        targetUrl: '',
        coverUrl:'',
        descImageUrl:'',
        liveStreamVideoList:[
          // {
          //   key: '1',
          //   title: 'John Brown',
          //   time: '32',
          //   type: 1,
          // },
        ],
      },
      rules: {
        targetUrl: [{ required: true, message: '请输入', trigger: 'blur' }],
        coverUrl: [{ required: true, message: '请上传', trigger: 'blur' }],
        descImageUrl: [{ required: true, message: '请上传', trigger: 'blur' }],
       },
      labelCol: { span: 4},
      wrapperCol: { span: 14 },
      communityColumns:[
        {
          title: '标题',
          key: 'title',
          dataIndex: 'title',
          // scopedSlots: { customRender: 'tags' },
        }, {
          title: '时长',
          key: 'duration',
          dataIndex: 'duration',
          // scopedSlots: { customRender: 'tags' },
        }, {
          title: '类型',
          key: 'taskId',
          dataIndex: 'taskId',
          scopedSlots: { customRender: 'type' },
        }, {
          title: '操作',
          key: 'system',
          dataIndex: 'system',
          scopedSlots: { customRender: 'system' },
        },
      ],

      vertical:"vertical",
      pageNo:1,
      pageSize:10,
    }
  },
  watch: {
    activeKey(key) {
      console.log(key);
    },
  },
  mounted() {
    this.getList()
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"我的会议管理")
  },
  methods:{
    async getList() {
      this.spinning = true
      let data = {
        userName:'',
        year:this.year.slice(0,4),
        page_no:this.pageNo,
        page_size:this.pageSize,
        is_audit:this.radioValue,
      }
      const response = await myManageList(data)
      if(response.code === 0){
        this.summarizeList = response.data
        this.total = response.count
      }
      this.spinning = false
    },
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }else {
        this.yearShowOne = false;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.year = value;
      this.page_no = 1
      if(this.year){
        this.year = moment(this.year).format('YYYY')
      }
    //  刷新
      this.getList()
    },
    onChange(){
      //  刷新
      this.getList()
    },
    seachBtn(){
//  刷新
      this.getList()
    },
    resetBtn(){
      this.pageNo = 1
      this.year = '2022'
      this.radioValue = '0'
//  刷新
      this.getList()
    },
    resetForm(){
      this.visible = false
    },
    lookBtn(id){
      this.visible = true
      //查看
      this.manageDetail(id)
    },
    async manageDetail(id) {
      const response = await manageListDetail(id)
      if(response.code === 0){
        this.form = response.data
      }
    },
    // async upPosterCover() {
    //   let inputDOM = this.$refs.descImageUrl.files[0];
    //   //判断图片大小
    //   // if (inputDOM.size < 3145728) {
    //     const image = await update(inputDOM)
    //     if (image.code === 0) {
    //       this.form.descImageUrl = image.data.url
    //       this.$message.success("上传成功")
    //       document.getElementById('descImageUrlId').value = null;
    //     } else {
    //       this.$message.warning("上传失败")
    //     }
    //   // } else {
    //   //   this.$message.warning("请上传小于3M的图片！")
    //   // }
    // },
    // async upDetailsCover() {
    //   let inputDOM = this.$refs.detailCoverUrl.files[0];
    //   //判断图片大小
    //   // if (inputDOM.size < 3145728) {
    //     const image = await update(inputDOM)
    //     if (image.code === 0) {
    //       this.form.coverUrl = image.data.url
    //       this.$message.success("上传成功")
    //       document.getElementById('detailCoverUrlId').value = null;
    //     } else {
    //       this.$message.warning("上传失败")
    //     }
    //   // } else {
    //   //   this.$message.warning("请上传小于3M的图片！")
    //   // }
    // },
    //顶部复制
    copy (item) {
      //创建input标签
      var input = document.createElement('input')
      //将input的值设置为需要复制的内容
      input.value = item;
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //成功提示信息
      this.$message.success('success!')
      //移除input标签
      document.body.removeChild(input)
    },
    pageChange(pageNumber) {
      this.pageNo = pageNumber
    },
    openVideo(url) {
      window.open(url)
    },
    openVue(list){
      this.$router.push({path:'detailsMeetings',query:{id:list.id,createdById:list.createdById,type:'1',title:list.title,time:list.liveDate}})
    },
    dataBtn(id){
      //路由跳转
      this.$router.push({path:'/liveInformation',query:{live_metting_id:id}})
    },
  }
}
</script>
<style lang="scss" scoped>
.ConferenceSettlement{
  min-height: 110%;
  background-color: #F0F2F5;
  .top{
    height: 100px;
    background-color: #fff;
    width: 100%;
    border-bottom: 20px solid #F0F2F5;
    display: flex;
    .liveMeet-top-input{
      display: flex;
      justify-content: center;
      height: 50px;
      line-height: 30px;
      padding-top: 20px;
      .input{
        width: 70%;
        margin-left: 10px;
      }
    }
  }
  .box-card{
    height: 270px;
    margin-bottom: 10px;
    .card{
      background-color: #fff;
      //border:1px solid #f5f5f5;
      box-shadow: 0px 0px 5px 3px #ececec;
      width: 98%;
      height: 100%;
      margin: auto;
      .card-img{
        width: 100%;
        position: relative;
        .card-top{
          height: 30px;
          line-height: 30px;
          color: #fff;
          width: 100%;
          //background: rgba(0,0,0,0.5);
          position: absolute;
          top: 0;
          display: flex;
          justify-content: right;
        }.card-bottom{
          height: 30px;
          line-height: 30px;
          color: #fff;
          padding-left: 10px;
          width: 100%;
          background: rgba(0,0,0,0.5);
          position: absolute;
          bottom: 0;
          display: flex;
          justify-content: space-between;
        }
        .img{
          width: 100%;
          height: 150px;
          border:1px solid #f5f5f5;
        }
      }
      .title{
        height: 70px;color: #333333;padding: 10px;
        .title-t{
          font-size: 14px;
          font-weight: bold;
          width: 100%;
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          word-break: break-all;
        }
        .title-liveTime{
          font-size: 12px;color: #8c8a8a;
        }
      }
      .bottom{
        font-size: 12px;
        background: #f5f5f5;
        height: 50px;
        line-height: 50px;
        color: #8c8a8a;
        display: flex;
        justify-content: space-around;
        cursor: pointer;
      }
    }
  }
}
.modelSty{
  width: 100%;
  .title-model{
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
}
.uploadImg{
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_deyails{
  border: #DDDDDD 2px dashed;
  width: 220px;
  height: 130px;
  margin-left: 20px;
  text-align: center;
}
.upload_poster{
  border: #DDDDDD 2px dashed;
  width: 110px;
  min-height: 120px;
  margin-left: 20px;
  text-align: center;
}
</style>